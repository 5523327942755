const rpcUrl = 'https://rpc.mainnet.taiko.xyz/',
    blockExplorers = 'https://taikoscan.io/'
export default {
    id: 167000,
    name: 'Taiko',
    network: 'taiko',
    nativeCurrency: {name: 'Ether', symbol: 'ETH', decimals: 18},
    rpcUrls: {
        default: {
            http: [rpcUrl],
        },
        public: {
            http: [rpcUrl],
        },
    },
    blockExplorers: {
        etherscan: {name: 'Taikoscan', url: blockExplorers},
        default: {name: 'Taikoscan', url: blockExplorers},
    },
    contracts: {},
}