const rpcUrl = 'https://rpc.blast.io/',
    blockExplorers = 'https://blastscan.io/'
export default {
    id: 81457,
    name: 'Blast',
    network: 'blast',
    nativeCurrency: {name: 'Ether', symbol: 'ETH', decimals: 18},
    rpcUrls: {
        default: {
            http: [rpcUrl],
        },
        public: {
            http: [rpcUrl],
        },
    },
    blockExplorers: {
        etherscan: {name: 'Blastscan', url: blockExplorers},
        default: {name: 'Blastscan', url: blockExplorers},
    },
    contracts: {},
}