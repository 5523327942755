const rpcUrl = 'https://rpc.vizing.com',
    blockExplorers = 'https://explorer.vizing.com'
export default {
    id: 28518,
    name: 'Vizing Mainnet',
    network: 'vizing-mainnet',
    nativeCurrency: {name: 'Ether', symbol: 'ETH', decimals: 18},
    rpcUrls: {
        default: {
            http: [rpcUrl],
        },
        public: {
            http: [rpcUrl],
        },
    },
    blockExplorers: {
        etherscan: {name: 'Vizing Mainnet explorer', url: blockExplorers},
        default: {name: 'Vizing Mainnet explorer', url: blockExplorers},
    },
    contracts: {},
}