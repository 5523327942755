const rpcUrl = 'https://bsc-dataseed.binance.org/',
    blockExplorers = 'https://bscscan.com/'
export default {
    id: 56,
    name: 'BNB Chain',
    network: 'bnb',
    nativeCurrency: {name: 'BNB', symbol: 'BNB', decimals: 18},
    rpcUrls: {
        default: {
            http: [rpcUrl],
        },
        public: {
            http: [rpcUrl],
        },
    },
    blockExplorers: {
        etherscan: {name: 'BSCscan', url: blockExplorers},
        default: {name: 'BSCscan', url: blockExplorers},
    },
    contracts: {},
}