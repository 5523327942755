const rpcUrl = 'https://rpc.gobob.xyz',
    blockExplorers = 'https://explorer.gobob.xyz'
export default {
    id: 60808,
    name: 'BOB',
    network: 'bob',
    nativeCurrency: {name: 'Ether', symbol: 'ETH', decimals: 18},
    rpcUrls: {
        default: {
            http: [rpcUrl],
        },
        public: {
            http: [rpcUrl],
        },
    },
    blockExplorers: {
        etherscan: {name: 'bobexplorer', url: blockExplorers},
        default: {name: 'bobexplorer', url: blockExplorers},
    },
    contracts: {},
}