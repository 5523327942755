import store from './store'
import {message} from 'antd'
import languages from 'languages'
import {connect} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import {divide as npDivide, times as npTimes, plus as npPlus} from "number-precision";

export const
    requireDefault = (name, suffix = 'png') => require(`./assets/${name}.${suffix}`),
    square = size => ({width: size, height: size}),
    circle = size => ({...square(size), borderRadius: '50%', overflow: 'hidden'}),
    isNotNull = data => data != null,
    isNotNullOrEmpty = data => isNotNull(data) && data.length > 0,
    setPath = (name = '') => '/' + name,
    setPathSlice = (name = '') => name.slice(1),
    isIndexOf = (array = [], data) => array.indexOf(data) !== -1,
    checkData = ([content, ...ks], defaultData) => {
        const contentIsNotNull = isNotNull(content), [k, ...k1] = ks
        // 判断数据是否存在
        return (!contentIsNotNull || isIndexOf(ks) || isIndexOf(ks, null))
            ? defaultData
            : (contentIsNotNull
                // 判断k是否存在
                ? (isNotNull(k)
                    // 判断k是否是有元素的数组
                    ? (typeof k === 'object' && isNotNull(k[0])
                        // 遍历ks，返回含有指定value的数组
                        ? ks.map(([v, ...v1]) => checkData([content[v], ...v1], defaultData))
                        // 获取多维对象的值
                        : checkData([content[k], ...k1], defaultData))
                    : content)
                // 数据不存在返回默认值
                : defaultData);
    },
    checkData0 = (...keys) => checkData(keys, 0),
    checkDataBool = (...keys) => checkData(keys, false),
    checkDataString = (...keys) => checkData(keys, ''),
    checkDataArray = (...keys) => checkData(keys, []),
    checkDataObject = (...keys) => checkData(keys, {}),
    checkDataFunction = (...keys) => checkData(keys, () => {
    }),
    numberCheckData0 = (...keys) => Number(checkData0(...keys)),
    ternaryArray = (condition, array = []) => condition ? array : [],
    ternaryObject = (condition, object = []) => condition ? object : {},
    dispatch = (props, state = {}) => checkDataFunction(props, 'dispatch')({type: '', ...state}),
    getLanguage = (defaultData, ...keys) => {
        const getLanguage = (languageIndex, defaultData) => checkData([languages, languageIndex, ...keys], defaultData)
        return getLanguage(languageIndex(), getLanguage(0, defaultData))
    },
    languageIndex = () => store.getState().languageIndex,
    language = (...keys) => getLanguage('', ...keys),
    languageFunction = (...keys) => getLanguage(() => '', ...keys),
    coinIndex = () => Number(store.getState().coinIndex),
    currentCoins = () => JSON.parse(store.getState().currentCoins),
    currentCoin = () => checkDataObject(currentCoins(), window.location.pathname.slice(1).split('/')[0]),
    connectClass = classData => connect(data => ({data}), dispatch => ({dispatch}))(classData),
    pathname = () => useLocation().pathname,
    path = () => pathname().slice(1),
    navigate = path => useNavigate()(`/${path}`),
    middleEllipsis = (str = '', num = 0) => {
        return checkDataString(str).length > num * 2
            ? `${str.slice(0, num)}...${str.slice(-num)}`
            : str
    },
    toast = m => message.useMessage()[0].info(m),
    isDev = process.env.NODE_ENV === 'development',
    timestampToDate = timestamp => new Date(timestamp)
        .toLocaleString('cn', {hour12: false}),
    toFixedNum = 9,//sessionStorage.getItem('dev') ? 9 : 8,
    numberPointFixed = num => {
        num = num.toString()
        const pointIndex = num.indexOf('.');
        return (pointIndex === -1 ? num : num.slice(0, pointIndex + toFixedNum + 1)).replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
        // sessionStorage.getItem('dev')
        //     ? (pointIndex === -1 ? num : num.slice(0, pointIndex + toFixedNum + 1)).replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
        //     : npPlus(pointIndex === -1 ? num : num.slice(0, pointIndex + toFixedNum + 1), 0)
    },
    numberToFixed = num => {
        const denominator = 10 ** toFixedNum
        return Number(npDivide(Math.floor(npTimes(Number(isNaN(num) ? 0 : num), denominator)), denominator).toFixed(toFixedNum))
    },
    getFullNum = (num, zero) => {
        num = Number(num)
        if (isNaN(num)) {
            return zero ? 0 : num
        }
        num = numberToFixed(num)
        if (!/e/i.test('' + num)) {
            return numberPointFixed(num);
        }
        return num.toFixed(toFixedNum).replace(/\.?0+$/, "")
    },
    toFixedNum18 = 18,
    numberPointFixed18 = num => {
        num = num.toString()
        const pointIndex = num.indexOf('.');
        return (pointIndex === -1 ? num : num.slice(0, pointIndex + toFixedNum18 + 1)).replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
        // sessionStorage.getItem('dev')
        //     ? (pointIndex === -1 ? num : num.slice(0, pointIndex + toFixedNum18 + 1)).replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
        //     : npPlus(pointIndex === -1 ? num : num.slice(0, pointIndex + toFixedNum18 + 1), 0)
    },
    numberToFixed18 = num => {
        const denominator = 10 ** toFixedNum18
        return Number(npDivide(Math.floor(npTimes(Number(isNaN(num) ? 0 : num), denominator)), denominator).toFixed(toFixedNum18))
    },
    getFullNum18 = (num, zero) => {
        num = Number(num)
        if (isNaN(num)) {
            return zero ? 0 : num
        }
        num = numberToFixed18(num)
        // if (!/e/i.test('' + num)) {
        //     return numberPointFixed(num);
        // }
        num = num.toFixed(toFixedNum18).replace(/\.?0+$/, "")
        const numArr = num.split('.'), num0 = checkDataString(numArr, 0), num1 = checkDataString(numArr, 1),
            index = num1.split('').findIndex(char => /[1-9]/.test(char))
        // 如果没有找到，返回-1
        return num1.length === 0 ? num0 : (
            index <= 5
                ? numberPointFixed(num)
                : `${num0}.${index === 0 ? '' : (index <= 1 ? '0' : `0${
                    index.toString().split('').map(v => ['₀', '₁', '₂', '₃', '₄', '₅', '₆', '₇', '₈', '₉'][v]).join('')
                }`)}${num1.substr(index, 4)}`
        );
    }