const rpcUrl = 'https://data-seed-prebsc-1-s1.bnbchain.org:8545',
    blockExplorers = 'https://testnet.bscscan.com'
export default {
    id: 97,
    name: 'BNB Smart Chain Testnet',
    network: 'bnb-testnet',
    nativeCurrency: {name: 'BNB', symbol: 'BNB', decimals: 18},
    rpcUrls: {
        default: {
            http: [rpcUrl],
        },
        public: {
            http: [rpcUrl],
        },
    },
    blockExplorers: {
        etherscan: {name: 'BNBtestnet', url: blockExplorers},
        default: {name: 'BNBtestnet', url: blockExplorers},
    },
    contracts: {},
}