const rpcUrl = 'https://rpc.cardona.zkevm-rpc.com',
    blockExplorers = 'https://cardona-zkevm.polygonscan.com'
export default {
    id: 2442,
    name: 'Polygon Zkevm Cardona',
    network: 'polygon-zkevm-cardona',
    nativeCurrency: {name: 'Ether', symbol: 'ETH', decimals: 18},
    rpcUrls: {
        default: {
            http: [rpcUrl],
        },
        public: {
            http: [rpcUrl],
        },
    },
    blockExplorers: {
        etherscan: {name: 'Polygon Zkevm Cardona', url: blockExplorers},
        default: {name: 'Polygon Zkevm Cardona', url: blockExplorers},
    },
    contracts: {},
}