import React, {useEffect, useRef} from 'react';
import * as echarts from 'echarts';
import {checkData, checkDataString, getFullNum18, ternaryObject} from "./functions";
import {divide as npDivide, minus as npMinus, plus as npPlus, times as npTimes} from 'number-precision'

export default ({clientWidth, chartMultiple, chartSmallMultiple, setData, data = []}) => {
    const chartRef = useRef(null),
        setChartDataIndex = chartDataIndex => setData({chartDataIndex}),
        newData = key => data.map(v => v[key]),
        seriesData = newData('price'),
        markLineYAxis = checkDataString(seriesData, seriesData.length - 1).toString(),
        isPhone = chartMultiple !== 1,
        markLineLabelWidth = ternaryObject(
            isPhone,
            {width: clientWidth / 9.5}
        ),
        markLineLabelPadding = (() => {
            const size = clientWidth / 500 * chartSmallMultiple
            return [size, isPhone ? 0 : size * 2, size * chartSmallMultiple, isPhone ? 0 : size * 2]
        })(),
        markLineLabelTextStyle = {
            textStyle: {
                color: '#FFB9C2',
                ...ternaryObject(isPhone, {align: 'center'}),
                fontSize: `${npTimes(.2, chartSmallMultiple)}rem`,
            }
        }
    useEffect(
        () => {
            const chart = echarts.init(
                chartRef.current,
                {
                    "color": [
                        "#FF7789"
                    ],
                    "backgroundColor": "rgba(0,0,0,0)",
                    "textStyle": {},
                    "title": {
                        "textStyle": {
                            "color": "transparent"
                        },
                        "subtextStyle": {
                            "color": "transparent"
                        }
                    },
                    "line": {
                        "itemStyle": {
                            "borderWidth": "0"
                        },
                        "lineStyle": {
                            "width": "2"
                        },
                        "symbolSize": "0",
                        "symbol": "circle",
                        "smooth": false
                    },
                    "radar": {
                        "itemStyle": {
                            "borderWidth": "0"
                        },
                        "lineStyle": {
                            "width": "2"
                        },
                        "symbolSize": "0",
                        "symbol": "circle",
                        "smooth": false
                    },
                    "bar": {
                        "itemStyle": {
                            "barBorderWidth": 0,
                            "barBorderColor": "transparent"
                        }
                    },
                    "pie": {
                        "itemStyle": {
                            "borderWidth": 0,
                            "borderColor": "transparent"
                        }
                    },
                    "scatter": {
                        "itemStyle": {
                            "borderWidth": 0,
                            "borderColor": "transparent"
                        }
                    },
                    "boxplot": {
                        "itemStyle": {
                            "borderWidth": 0,
                            "borderColor": "transparent"
                        }
                    },
                    "parallel": {
                        "itemStyle": {
                            "borderWidth": 0,
                            "borderColor": "transparent"
                        }
                    },
                    "sankey": {
                        "itemStyle": {
                            "borderWidth": 0,
                            "borderColor": "transparent"
                        }
                    },
                    "funnel": {
                        "itemStyle": {
                            "borderWidth": 0,
                            "borderColor": "transparent"
                        }
                    },
                    "gauge": {
                        "itemStyle": {
                            "borderWidth": 0,
                            "borderColor": "transparent"
                        }
                    },
                    "candlestick": {
                        "itemStyle": {
                            "color": "transparent",
                            "color0": "transparent",
                            "borderColor": "transparent",
                            "borderColor0": "transparent",
                            "borderWidth": "0"
                        }
                    },
                    "graph": {
                        "itemStyle": {
                            "borderWidth": 0,
                            "borderColor": "transparent"
                        },
                        "lineStyle": {
                            "width": "0",
                            "color": "transparent"
                        },
                        "symbolSize": "0",
                        "symbol": "circle",
                        "smooth": false,
                        "color": [
                            "#FF7789"
                        ],
                        "label": {
                            "color": "transparent"
                        }
                    },
                    "map": {
                        "itemStyle": {
                            "areaColor": "#eee",
                            "borderColor": "#444",
                            "borderWidth": 0.5
                        },
                        "label": {
                            "color": "#000"
                        },
                        "emphasis": {
                            "itemStyle": {
                                "areaColor": "rgba(255,215,0,0.8)",
                                "borderColor": "#444",
                                "borderWidth": 1
                            },
                            "label": {
                                "color": "rgb(100,0,0)"
                            }
                        }
                    },
                    "geo": {
                        "itemStyle": {
                            "areaColor": "#eee",
                            "borderColor": "#444",
                            "borderWidth": 0.5
                        },
                        "label": {
                            "color": "#000"
                        },
                        "emphasis": {
                            "itemStyle": {
                                "areaColor": "rgba(255,215,0,0.8)",
                                "borderColor": "#444",
                                "borderWidth": 1
                            },
                            "label": {
                                "color": "rgb(100,0,0)"
                            }
                        }
                    },
                    "categoryAxis": {
                        "axisLine": {
                            "show": false,
                            "lineStyle": {
                                "color": "transparent"
                            }
                        },
                        "axisTick": {
                            "show": false,
                            "lineStyle": {
                                "color": "#5470c6"
                            }
                        },
                        "axisLabel": {
                            "show": true,
                            "color": "#000000"
                        },
                        "splitLine": {
                            "show": false,
                            "lineStyle": {
                                "color": [
                                    "#E0E6F1"
                                ]
                            }
                        },
                        "splitArea": {
                            "show": false,
                            "areaStyle": {
                                "color": [
                                    "rgba(250,250,250,0.2)",
                                    "rgba(210,219,238,0.2)"
                                ]
                            }
                        }
                    },
                    "valueAxis": {
                        "axisLine": {
                            "show": false,
                            "lineStyle": {
                                "color": "transparent"
                            }
                        },
                        "axisTick": {
                            "show": false,
                            "lineStyle": {
                                "color": "#5470c6"
                            }
                        },
                        "axisLabel": {
                            "show": true,
                            "color": "#000000"
                        },
                        "splitLine": {
                            "show": false,
                            "lineStyle": {
                                "color": [
                                    "#E0E6F1"
                                ]
                            }
                        },
                        "splitArea": {
                            "show": false,
                            "areaStyle": {
                                "color": [
                                    "rgba(250,250,250,0.2)",
                                    "rgba(210,219,238,0.2)"
                                ]
                            }
                        }
                    },
                    "logAxis": {
                        "axisLine": {
                            "show": false,
                            "lineStyle": {
                                "color": "#6E7079"
                            }
                        },
                        "axisTick": {
                            "show": false,
                            "lineStyle": {
                                "color": "#6E7079"
                            }
                        },
                        "axisLabel": {
                            "show": false,
                            "color": "#5470c6"
                        },
                        "splitLine": {
                            "show": false,
                            "lineStyle": {
                                "color": [
                                    "#E0E6F1"
                                ]
                            }
                        },
                        "splitArea": {
                            "show": false,
                            "areaStyle": {
                                "color": [
                                    "rgba(250,250,250,0.2)",
                                    "rgba(210,219,238,0.2)"
                                ]
                            }
                        }
                    },
                    "timeAxis": {
                        "axisLine": {
                            "show": false,
                            "lineStyle": {
                                "color": "#5470c6"
                            }
                        },
                        "axisTick": {
                            "show": false,
                            "lineStyle": {
                                "color": "#5470c6"
                            }
                        },
                        "axisLabel": {
                            "show": false,
                            "color": "#6E7079"
                        },
                        "splitLine": {
                            "show": false,
                            "lineStyle": {
                                "color": [
                                    "#E0E6F1"
                                ]
                            }
                        },
                        "splitArea": {
                            "show": false,
                            "areaStyle": {
                                "color": [
                                    "rgba(250,250,250,0.2)",
                                    "rgba(210,219,238,0.2)"
                                ]
                            }
                        }
                    },
                    "toolbox": {
                        "iconStyle": {
                            "borderColor": "transparent"
                        },
                        "emphasis": {
                            "iconStyle": {
                                "borderColor": "transparent"
                            }
                        }
                    },
                    "legend": {
                        "textStyle": {
                            "color": "transparent"
                        }
                    },
                    "tooltip": {
                        "axisPointer": {
                            "lineStyle": {
                                "color": "#FF7789",
                                "width": "1"
                            },
                            "crossStyle": {
                                "color": "#FF7789",
                                "width": "1"
                            }
                        }
                    },
                    "timeline": {
                        "lineStyle": {
                            "color": "transparent",
                            "width": "0"
                        },
                        "itemStyle": {
                            "color": "transparent",
                            "borderWidth": "0"
                        },
                        "controlStyle": {
                            "color": "transparent",
                            "borderColor": "transparent",
                            "borderWidth": "0"
                        },
                        "checkpointStyle": {
                            "color": "transparent",
                            "borderColor": "transparent"
                        },
                        "label": {
                            "color": "transparent"
                        },
                        "emphasis": {
                            "itemStyle": {
                                "color": "transparent"
                            },
                            "controlStyle": {
                                "color": "transparent",
                                "borderColor": "transparent",
                                "borderWidth": "0"
                            },
                            "label": {
                                "color": "transparent"
                            }
                        }
                    },
                    "visualMap": {
                        "color": [
                            "#FF7789"
                        ]
                    },
                    "dataZoom": {
                        "handleSize": "undefined%",
                        "textStyle": {}
                    },
                    "markPoint": {
                        "label": {
                            "color": "transparent"
                        },
                        "emphasis": {
                            "label": {
                                "color": "transparent"
                            }
                        }
                    }
                }
            )
            chart.setOption({
                grid: {
                    left: 0,
                    top: clientWidth / 100 * chartSmallMultiple,
                    right: clientWidth / (isPhone ? 17 : 15) * chartSmallMultiple,
                    bottom: clientWidth / (isPhone ? 30 : 50) * chartSmallMultiple,
                },
                tooltip: {
                    trigger: 'axis',
                    borderWidth: 0,
                    showContent: false,
                    formatter: ([v] = [{}]) => v.axisValue + '<br/>' + v.value,
                    axisPointer: {
                        axis: 'y',
                        type: 'cross',
                        lineStyle: {color: 'gray'},
                        crossStyle: {color: 'gray'},
                        label: {
                            borderRadius: 999,
                            ...markLineLabelWidth,
                            backgroundColor: 'black',
                            formatter: params => {
                                const {value} = params, isX = params.axisDimension === 'x'
                                if (isX) {
                                    setChartDataIndex(checkData([params, 'seriesData', 0, 'dataIndex']))
                                }
                                return isX ? value.toString().split('\n').join(' ') : getFullNum18(value).toString()
                            },
                            padding: markLineLabelPadding,
                            ...markLineLabelTextStyle,
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: newData('date'),
                    axisLabel: {
                        textStyle: {color: 'white'},
                        fontSize: `${npTimes(.2, chartSmallMultiple)}rem`,
                        // x轴最多5条
                        interval: Math.floor(npDivide(data.length, npPlus(5, 1))),
                        formatter: (v, i) => i === 0 ? '' : v.split('\n').join(isPhone ? '\n\n' : '\n')
                    },
                },
                yAxis: {
                    type: 'value',
                    position: 'right',
                    axisLabel: {
                        textStyle: {color: 'white'},
                        fontSize: `${npTimes(.2, chartSmallMultiple)}rem`,
                        formatter: v => v === 0 ? '' : getFullNum18(v).toString()
                    },
                },
                series: [
                    {
                        data: seriesData,
                        type: 'line',
                        markLine: {
                            silent: true,
                            symbol: ['none'],
                            data: [
                                {
                                    yAxis: markLineYAxis,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#FF7789',
                                    },
                                },
                            ],
                            label: {
                                show: true,
                                ...markLineLabelWidth,
                                borderRadius: 999,
                                backgroundColor: 'black',
                                distance: isPhone ? 23 : 0,
                                padding: markLineLabelPadding,
                                ...markLineLabelTextStyle,
                                formatter: v => getFullNum18(v.value).toString()
                            },
                        },
                        areaStyle: {
                            color: {
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                type: 'linear',
                                colorStops: [
                                    {offset: 0.28, color: '#FF6176'},
                                    {offset: 0.9708, color: 'rgba(255, 185, 194, 0)'}
                                ],
                                global: false
                            }
                        }
                    }
                ]
            })
            // console.log('chart ---------------------------------');
            return () => chart.dispose()
        },
        []
    )// 确保chart只初始化一次

    useEffect(() => {
        const chart = echarts.getInstanceByDom(chartRef.current),
            oldData = key => JSON.stringify(chart.getOption()[key][0].data),
            xAxis = newData('date'),
            series = newData('price')
        if (JSON.stringify(xAxis) !== oldData('xAxis') ||
            JSON.stringify(series) !== oldData('series')) {
            // console.log('chart =================================');
            const lastIndex = data.length - 1
            setChartDataIndex(lastIndex < 0 ? 0 : lastIndex)
            chart.setOption({
                xAxis: {data: xAxis},
                series: [{
                    data: series,
                    markLine: {data: [{yAxis: markLineYAxis}]}
                }]
            })
        }
    }, [data])
    return <div ref={chartRef} className={'width100 flexGrow1Column'}
                style={{fontSize: `${npTimes(.3, chartMultiple)}rem`}}/>
}
