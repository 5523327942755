import {createStore} from "redux";

export default createStore((
    state = {
        isPhoneClientWidth: false,
        coinIndex: sessionStorage.getItem('coinIndex') || 0,
        languageIndex: sessionStorage.getItem('languageIndex') || 0,
        currentCoins: sessionStorage.getItem('currentCoins') || '{}',
        contractStatuData: localStorage.getItem('contractStatuData') || '{}',
    },
    // @ts-ignore
    newState = {},
) => ({
    // @ts-ignore
    ...state,
    ...newState
}))