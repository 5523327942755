const rpcUrl = 'https://testnet.rpc.gobob.xyz',
    blockExplorers = 'https://testnet-explorer.gobob.xyz'
export default {
    id: 111,
    name: 'BOB Testnet',
    network: 'bob-testnet',
    nativeCurrency: {name: 'Ether', symbol: 'ETH', decimals: 18},
    rpcUrls: {
        default: {
            http: [rpcUrl],
        },
        public: {
            http: [rpcUrl],
        },
    },
    blockExplorers: {
        etherscan: {name: 'Blockscout', url: blockExplorers},
        default: {name: 'Blockscout', url: blockExplorers},
    },
    contracts: {},
}