const rpcUrl = 'https://rpc-sepolia.vizing.com',
    blockExplorers = 'https://explorer-sepolia.vizing.com/'
export default {
    id: 28516,
    name: 'Vizing Testnet',
    network: 'orbiter-vizing-testnet',
    nativeCurrency: {name: 'Ether', symbol: 'ETH', decimals: 18},
    rpcUrls: {
        default: {
            http: [rpcUrl],
        },
        public: {
            http: [rpcUrl],
        },
    },
    blockExplorers: {
        etherscan: {name: 'Orbiter Vizing Testnet explorer', url: blockExplorers},
        default: {name: 'Orbiter Vizing Testnet explorer', url: blockExplorers},
    },
    contracts: {},
}